/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

$(document).ready( function () {

    var prefilterIgnoreArray = [
        // specify routes for prefilter to ignore, useful for scenarios where we use FormData as it appends as a string
    ];

    // $('body').on('click', '.submit-double-block', function(x) {
    //     var $thisButton = $(this);
    //     $thisButton.html('<i class="fa fa-fw fa-spinner fa-spin"></i> ');
    //     $thisButton.prop('disabled', true);
    // })

    $.ajaxPrefilter(function(options, originalOptions, xhr) { // this will run before each request
        var token = $('meta[name="csrf-token"]').attr('content');
        var notIgnored = true;
        var urlToCheck = options.url;
        $.each(prefilterIgnoreArray, function(index, item) {
            if(urlToCheck.indexOf(item) >= 0) {
                notIgnored = false;
            }
        });

        if (options.type.toLowerCase() === "post" && notIgnored) {
            // initialize `data` to empty string if it does not exist
            options.data = options.data || "";

            // add leading ampersand if `data` is non-empty
            options.data += options.data?"&":"";

            // add _token entry
            options.data += "_token=" + token;
        }
    });

    $('.data-table').DataTable();

    $('.table-container').on('click', 'tr[data-href]', function() {
        if($(this).attr('data-href').length) {
            window.location = $(this).attr('data-href');
        }
    });

    $('.fancy-select').selectpicker();

    $('.money-input').on('change', function() {
        var $moneyInput = $(this);
        var currentValue = $moneyInput.val();
        $moneyInput.val(parseFloat(currentValue.replace(/[^\d.-]/g, '')).toFixed(2));
    });

    var datepickerArgs = {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        locale: {
            "firstDayOfWeek": 1 // start week on Monday
        },
        onChange: function(selectedDates, dateStr, instance) {
            $(instance.element).trigger('dateChanged'); // bubble up to the DOM
        }
    }
    $('input.datepicker-field').flatpickr(datepickerArgs);

    $.fn.checkFields = function() {
        var selectedPaymentTypeId = $(this).val();
        $.get('/payment-requests/' + selectedPaymentTypeId + '/get-fields', function(response) {
            $('.payment-request-form .hideable').hide();
            $.each(response, function(index, field) {
                $('[name="'+field+'"]').parents('.hideable').show();
            });
        });
    }

    if($('#payment_type_id').length) {
        $('.hidden-payment-form-container').delay(500).fadeIn(200);
        $('#payment_type_id').on('change', function() {
            // payment-requests/{paymentType}/get-fields
            $(this).checkFields();
        }).checkFields();
    }

    $('#create-payment-request, #resend-payment-request').on('click', function(x) {
        x.preventDefault();
        var createPaymentModal = bootbox.prompt({
            title: '<i class="fal fa-paper-plane"></i> Send Payment Request',
            message: '<label class="font-weight-bold"><i class="fal fa-envelope"></i> Please enter the email address to send to:</label>',
            centerVertical: true,
            buttons: {
                confirm: {
                    label: '<i class="fal fa-check-circle"></i> Send Now',
                    className: 'btn-success',
                },
                cancel: {
                    label: '<i class="fal fa-times-circle"></i> Cancel',
                    className: 'btn-primary',
                }
            },
            callback: function(result) {
                if (result !== null) {
                    if(!result) {
                        bootbox.alert({
                            size: 'small',
                            title: '<i class="fal fa-times-circle"></i> Sorry',
                            message: "You must enter an email address to proceed.",
                            centerVertical: true,
                        });
                        return false;
                    }
                    var $acceptButton = $(this).find('.bootbox-accept');
                    $acceptButton.prop('disabled', true);
                    var originalHtml = $acceptButton.html();
                    $acceptButton.html('<i class="fa fa-spin fa-circle-notch"></i> Sending...');
                    $('.payment-request-form').prepend('<input type="hidden" name="sent_to" value="' + result + '">');
                    $('.payment-request-form').submit();
                    return false;
                }
            }
        });
    });

    $('.delete-button').on('click', function(x) {
        x.preventDefault();
        var $deleteButton = $(this);
        var deleteItemModal = bootbox.confirm({
            title: '<i class="fal fa-trash"></i> Are you sure?',
            message: '<p class="font-weight-bold"><i class="fal fa-info-circle"></i> This item will be deleted and cannot be undone, are you sure you wish to proceed?',
            centerVertical: true,
            buttons: {
                confirm: {
                    label: '<i class="fal fa-check-circle"></i> Delete Now',
                    className: 'btn-danger',
                },
                cancel: {
                    label: '<i class="fal fa-times-circle"></i> Cancel',
                    className: 'btn-secondary',
                }
            },
            callback: function (result) {
                if(result) {
                    $deleteButton.parents('form').submit();
                }
            }
        });

    });

});
